@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  box-sizing: border-box;
  background-color: #f1e8dd;
}

html {
  /* Pode manter aqui, mas vamos adicionar no contêiner específico também */
  scroll-behavior: smooth;
}

header {
  width: 100%;
  box-sizing: border-box;
}

/* Hero Section */
.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.text-center {
  text-align: center;
}

.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Projects Section */
.grid {
  display: grid;
  gap: 1rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.object-cover {
  object-fit: cover;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.zoom-in {
  animation: zoomIn 0.3s ease forwards;
}

.zoom-out {
  animation: zoomOut 0.3s ease forwards;
}

.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

/* About Us Section */
.flex {
  display: flex;
}

.md\\:flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.w-full {
  width: 100%;
}

/* Contact Section */
.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.focus\\:outline-none {
  outline: none;
}

.focus\\:shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.bg-hover {
  background-color: #C18B68; /* Cor para o hover do botão */
}

/* Mantém o estilo da scrollbar em desktops */
@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent; /* Fundo transparente para o track */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Certifique-se que o track também seja transparente */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D2A67D; /* Cor base para o thumb */
    border-radius: 10px;
    border: 1px solid transparent; /* Bordas transparentes para destacar o thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #C18B68; /* Um tom mais escuro da cor base para o hover */
  }
}

/* Esconde a scrollbar em dispositivos móveis */
@media (max-width: 767px) {
  ::-webkit-scrollbar {
    display: none; /* Oculta a scrollbar completamente em dispositivos móveis */
  }

  /* Adiciona para Firefox */
  body {
    scrollbar-width: none; /* Firefox oculta a scrollbar */
  }
}

.loader {
  border-top-color: #D2A67D;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-overflow-popup {
  overflow: hidden;
}

